<template>
    <v-container fluid>
        <!--Save-->
        <v-form ref="form" v-model="valid" lazy-validation>
            <v-card>
                <v-card-title>
                    <v-row>
                        <!----------------Tabs Names--------------------->
                        <v-col cols="12" lg="8" ms="8" sm="8">
                            <v-tabs v-model="tab">
                                <v-tabs-slider></v-tabs-slider>
                                <v-tab v-for="item in tabs" :key="item">
                                    {{ item }}
                                </v-tab>
                            </v-tabs>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-tabs-items v-model="tab">
                    <!----------------Main Info. Tab--------------------->
                    <v-tab-item>
                        <MainInfo :editedItem="editedItem" />
                    </v-tab-item>
                    <!----------------Installments Tab--------------------->
                    <v-tab-item> </v-tab-item>
                </v-tabs-items>
                <v-card-text>
                    <v-divider></v-divider>
                </v-card-text>
                <v-card-actions>
                    <v-row>
                        <v-col>
                            <v-row no-gutters>
                                <v-col cols="auto" class="ma-1">
                                    <v-btn :loading="loading" class="mx-1" text>
                                        {{ $t("new") }}
                                    </v-btn>
                                </v-col>
                                <v-col cols="auto" class="ma-1">
                                    <v-btn
                                        class="mx-1"
                                        color="primary"
                                        :loading="loading"
                                        :disabled="!valid"
                                    >
                                        <v-icon
                                            >mdi-content-save-outline</v-icon
                                        >
                                        {{ $t("save") }}
                                    </v-btn></v-col
                                >
                                <v-col cols="auto" class="ma-1">
                                    <v-btn
                                        class="mx-1"
                                        text
                                        :loading="loading"
                                        :disabled="!valid"
                                    >
                                        <v-icon>mdi-printer-outline</v-icon>
                                        {{ $t("print") }}
                                    </v-btn></v-col
                                >
                                <v-col cols="auto" class="ma-1">
                                    <v-switch
                                        class="mt-0 mx-1"
                                        :loading="loading"
                                        hide-details
                                        :label="$t('bill.isLock')"
                                    ></v-switch
                                ></v-col>
                                <v-col cols="auto" class="ma-1">
                                    <v-switch
                                        class="mt-0 mx-1"
                                        :loading="loading"
                                        hide-details
                                        :label="$t('bill.isAccept')"
                                    ></v-switch
                                ></v-col>
                                <v-col
                                    cols="auto"
                                    class="ma-1"
                                    v-if="billType != 'InstallmentsSale'"
                                >
                                    <v-btn
                                        class="mx-1"
                                        :loading="loading"
                                        outlined
                                        :disabled="!valid"
                                        color="warning"
                                    >
                                        <v-icon>mdi-credit-card-outline</v-icon>
                                        {{ $t("bill.pay") }}
                                    </v-btn></v-col
                                >
                                <v-col
                                    cols="auto"
                                    class="ma-1"
                                    v-if="billType != 'InstallmentsSale'"
                                >
                                    <v-btn :loading="loading" text class="mx-1">
                                        {{ $t("voucher.vouchers") }}
                                    </v-btn></v-col
                                >
                            </v-row>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn
                                :loading="loading"
                                text
                                color="red"
                                class="mx-1 mt-1"
                            >
                                <v-icon>mdi-delete-outline</v-icon>
                                {{ $t("delete") }}
                            </v-btn></v-col
                        >
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-container>
</template>

<script>
import MainInfo from "./MainInfo.vue";
import moment from "moment";

export default {
    components: {
        MainInfo
    },
    props: {
        billType: {
            type: String,
            required: true
        }
    },
    computed: {
        tabs() {
            var tabs = [this.$t("bill.billInfo")];

            if (this.billType == "InstallmentsSale")
                tabs.push(this.$t("installments.main"));
            return tabs;
        }
    },
    created() {},
    methods: {},
    data() {
        return {
            loading: false,
            valid: true,
            tab: null,
            editedItem: {
                billGuid: null,
                billDate: moment(new Date()).format("yyyy-MM-DD"),
                payType: this.billType == "InstallmentsSale" ? 2 : 1,
                billType: this.billType,
                billBodies: []
            }
        };
    }
};
</script>

<style></style>
