<template>
    <v-container>
        <v-row class="mb-2">
            <v-col cols="12" md="2">
                <v-text-field
                    outlined
                    readonly
                    type="number"
                    min="0"
                    dense
                    v-model="editedItem.billNumber"
                    :label="$t('bill.billNumber')"
                    hide-details
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
                <template>
                    <div>
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template
                                v-slot:activator="{
                                    on,
                                    attrs
                                }"
                            >
                                <v-text-field
                                    hide-details
                                    outlined
                                    dense
                                    :rules="rules"
                                    v-model="editedItem.billDate"
                                    :label="$t('bill.billDate')"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="editedItem.billDate"
                                :active-picker.sync="activePicker"
                                :max="
                                    new Date(
                                        Date.now() -
                                            new Date().getTimezoneOffset() *
                                                60000
                                    )
                                        .toISOString()
                                        .substr(0, 10)
                                "
                                min="1950-01-01"
                                @change="saveDate"
                            ></v-date-picker>
                        </v-menu>
                    </div>
                </template>
            </v-col>
            <v-col cols="12" md="2">
                <v-select
                    outlined
                    hide-details
                    dense
                    persistent-hint
                    :rules="rules"
                    v-model="currencyGuid"
                    :items="currencies"
                    @change="currencyChange"
                    item-text="currencySymbol"
                    item-value="currencyGuid"
                    :label="$t('voucher.currency')"
                ></v-select>
            </v-col>
            <v-col cols="12" md="1">
                <v-text-field
                    outlined
                    hide-details
                    :rules="rules"
                    dense
                    type="number"
                    min="0"
                    v-model="currencyValue"
                    :label="$t('voucher.currencyValue')"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
                <v-select
                    outlined
                    hide-details
                    dense
                    persistent-hint
                    :rules="rules"
                    v-model="editedItem.stockGuid"
                    :items="stocks"
                    item-text="stockName"
                    item-value="stockGuid"
                    :label="$t('stock.stock')"
                ></v-select>
            </v-col>
            <v-col cols="12" md="2">
                <v-autocomplete
                    :label="$t('bill.payType')"
                    v-model="editedItem.payType"
                    outlined
                    :disabled="editedItem.billType == 'InstallmentsSale'"
                    hide-details
                    dense
                    :rules="rules"
                    hide-selected
                    :items="payTypes"
                    item-text="key"
                    item-value="value"
                >
                </v-autocomplete>
            </v-col>
            <!------------------------------------------------>
            <v-col cols="12" md="3">
                <v-autocomplete
                    :label="$t('customer.customer')"
                    v-model="editedItem.customerGuid"
                    outlined
                    hide-details
                    dense
                    hide-selected
                    :items="customers"
                    item-text="fullName"
                    item-value="customerGuid"
                    :rules="rules"
                >
                </v-autocomplete>
            </v-col>
            <v-col cols="12" md="9">
                <v-textarea
                    outlined
                    hide-details
                    dense
                    rows="1"
                    v-model="editedItem.notes"
                    :label="$t('bill.notes')"
                ></v-textarea>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="my-2">
            <v-btn
                color="primary darken-1"
                dark
                outlined
                class="float-right mx-2"
                @click="addItem"
            >
                <v-icon> mdi-plus</v-icon>
                {{ $t("add") + " " + $t("item.item") }}
            </v-btn>
            <v-col cols="12" md="12">
                <div>
                    <v-data-table
                        :headers="headers"
                        :items="editedItem.billBodies"
                        :loading="loading"
                        :loading-text="$t('loading')"
                        hide-default-footer
                        :height="320"
                        :disable-pagination="true"
                        class="myTable"
                    >
                        <template
                            v-slot:[`item.actions`]="{
                                item
                            }"
                        >
                            <v-tooltip top>
                                <template
                                    v-slot:activator="{
                                        on,
                                        attrs
                                    }"
                                >
                                    <v-icon
                                        @click="deleteBillBody(item)"
                                        small
                                        v-bind="attrs"
                                        v-on="on"
                                        color="red"
                                    >
                                        mdi-delete-outline
                                    </v-icon>
                                </template>
                                <span> {{ $t("delete") }}</span>
                            </v-tooltip>
                        </template>
                        <template
                            v-slot:[`item.barcode`]="{
                                item
                            }"
                        >
                            <v-text-field
                                flat
                                solo
                                type="number"
                                dense
                                v-model="item.barcode"
                                :autofocus="true"
                                hide-details
                                v-on:keyup.enter="addItem"
                                v-on:change="itemChanged(item, 1)"
                            ></v-text-field>
                        </template>
                        <template
                            v-slot:[`item.itemGuid`]="{
                                item
                            }"
                        >
                            <v-select
                                flat
                                solo
                                hide-details
                                dense
                                persistent-hint
                                :rules="rules"
                                v-model="item.itemGuid"
                                v-on:keyup.enter="addItem"
                                :items="items"
                                item-text="itemName"
                                item-value="itemGuid"
                                v-on:change="itemChanged(item, 2)"
                            ></v-select>
                        </template>
                        <template
                            v-slot:[`item.price`]="{
                                item
                            }"
                        >
                            <v-text-field
                                flat
                                solo
                                type="number"
                                min="0"
                                dense
                                v-model="item.price"
                                v-on:keyup.enter="addItem"
                                hide-details
                            ></v-text-field>
                        </template>
                        <template
                            v-slot:[`item.quantity`]="{
                                item
                            }"
                        >
                            <v-text-field
                                flat
                                solo
                                type="number"
                                min="0"
                                dense
                                v-model="item.quantity"
                                v-on:keyup.enter="addItem"
                                hide-details
                            ></v-text-field>
                        </template>
                        <template
                            v-slot:[`item.totalPrice`]="{
                                item
                            }"
                        >
                            <v-text-field
                                flat
                                solo
                                type="number"
                                min="0"
                                dense
                                v-model="item.totalPrice"
                                v-on:keyup.enter="addItem"
                                hide-details
                            ></v-text-field>
                        </template>
                        <template
                            v-slot:[`item.discount`]="{
                                item
                            }"
                        >
                            <v-text-field
                                flat
                                solo
                                type="number"
                                min="0"
                                dense
                                v-model="item.discount"
                                v-on:keyup.enter="addItem"
                                hide-details
                            ></v-text-field>
                        </template>
                        <template
                            v-slot:[`item.extra`]="{
                                item
                            }"
                        >
                            <v-text-field
                                flat
                                solo
                                type="number"
                                min="0"
                                dense
                                v-model="item.extra"
                                v-on:keyup.enter="addItem"
                                hide-details
                            ></v-text-field>
                        </template>
                        <template
                            v-slot:[`item.netPrice`]="{
                                item
                            }"
                        >
                            <v-text-field
                                flat
                                solo
                                type="number"
                                min="0"
                                dense
                                readonly
                                v-model="item.netPrice"
                                v-on:keyup.enter="addItem"
                                hide-details
                            ></v-text-field>
                        </template>
                        <template
                            v-slot:[`item.notes`]="{
                                item
                            }"
                        >
                            <v-text-field
                                flat
                                solo
                                dense
                                v-model="item.notes"
                                v-on:keyup.enter="addItem"
                                hide-details
                            ></v-text-field>
                        </template>
                    </v-data-table>
                </div>
            </v-col>
        </v-row>
        <!--Delete Bill-Body confirm-->
        <confirm-dialog
            :openDialog="deleteBillBodyConfirm"
            :onClicked="deleteBodyConfirm"
            :onClose="closeDeleteBody"
            :toolBarColor="'red'"
        ></confirm-dialog>
    </v-container>
</template>

<script>
import axios from "axios";
import ConfirmDialog from "../../../components/ConfirmDialog.vue";

export default {
    components: {
        ConfirmDialog
    },
    props: {
        editedItem: {
            type: Object,
            required: true
        }
    },
    created() {
        axios
            .get("Currencies/Get")
            .then(response => {
                this.currencies = response.data.data;
            })
            .catch(e => {
                this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
                console.log(e);
            })
            .finally(() => {
                this.loading = false;
            });

        axios
            .get("Stocks/Get")
            .then(response => {
                this.stocks = response.data.data;
            })
            .catch(e => {
                this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
                console.log(e);
            })
            .finally(() => {
                this.loading = false;
            });

        axios
            .get("Customers/Get")
            .then(response => {
                this.customers = response.data.data;
            })
            .catch(e => {
                this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
                console.log(e);
            })
            .finally(() => {
                this.loading = false;
            });

        axios
            .get("Items/Get")
            .then(response => {
                this.items = response.data.data.filter(function(obj) {
                    return obj.isGroup != true;
                });
            })
            .catch(e => {
                this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
                console.log(e);
            })
            .finally(() => {
                this.loading = false;
            });
    },
    watch: {
        menu(val) {
            val && setTimeout(() => (this.activePicker = "YEAR"));
        }
    },
    data() {
        return {
            loading: false,
            menu: false,
            deleteBillBodyConfirm: false,
            activePicker: null,
            rules: [value => !!value || value == 0 || this.$t("required")],
            currencies: [],
            currencyGuid: null,
            currencyValue: null,
            stocks: [],
            items: [],
            customers: [],
            payTypes: [
                { key: this.$t("payTypes.cash"), value: 1 },
                { key: this.$t("payTypes.delayed"), value: 2 }
            ],
            headers: [
                {
                    text: this.$t("billBody.barcode"),
                    value: "barcode"
                },
                {
                    text: this.$t("billBody.item"),
                    value: "itemGuid"
                },
                {
                    text: this.$t("billBody.price"),
                    value: "price"
                },
                {
                    text: this.$t("billBody.quantity"),
                    value: "quantity"
                },
                {
                    text: this.$t("billBody.totalPrice"),
                    value: "totalPrice"
                },
                {
                    text: this.$t("billBody.discount"),
                    value: "discount"
                },
                {
                    text: this.$t("billBody.extra"),
                    value: "extra"
                },
                {
                    text: this.$t("billBody.netPrice"),
                    value: "netPrice"
                },
                {
                    text: this.$t("billBody.notes"),
                    value: "notes"
                },

                { text: "", value: "actions" }
            ]
        };
    },
    methods: {
        saveDate(date) {
            this.$refs.menu.save(date);
        },
        currencyChange(val) {
            if (val != null || val != undefined) {
                if (this.editedItem.billGuid == null) {
                    var x = this.currencies.filter(function(obj) {
                        return obj.currencyGuid == val;
                    });

                    this.currencyValue = x[0].currencyValue;
                    this.isCurrencyMain = x[0].isMain;
                } else if (
                    this.editedItem.billGuid != null &&
                    this.currencyGuid != val
                ) {
                    var billCurrency = this.currencies.filter(function(obj) {
                        return obj.currencyGuid == val;
                    })[0];

                    this.currencyValue = billCurrency.currencyValue;
                    this.isCurrencyMain = billCurrency.isMain;
                } else {
                    var temp = this.currencyGuid;
                    var c = this.currencies.filter(function(obj) {
                        return obj.currencyGuid == temp;
                    });

                    this.currencyValue = this.editedItem.currencyValue;
                    this.isCurrencyMain = c[0].isMain;
                }
            }
        },
        addItem() {
            this.editedItem.billBodies.push({
                quantity: 0,
                discount: 0,
                extra: 0
            });
        },
        itemChanged: function(item, selection) {
            var index = this.editedItem.billBodies.indexOf(item);
            var row = this.editedItem.billBodies[index];

            if (this.currencyValue == null || this.currencyValue == 0) {
                this.$toast.warning(this.$t("error.InsertCurrencyValue"));
                this.editedItem.billBodies.splice(index, 1);
                return;
            }

            var selectedItems = null;

            if (selection == 1) {
                selectedItems = this.items.filter(function(obj) {
                    return (
                        obj.barcode1 == row.barcode ||
                        obj.barcode2 == row.barcode ||
                        obj.barcode2 == row.barcode
                    );
                });
            } else {
                selectedItems = this.items.filter(function(obj) {
                    return obj.itemGuid == row.itemGuid;
                });
            }

            if (
                selectedItems == undefined ||
                selectedItems == null ||
                selectedItems.length == 0
            ) {
                this.$toast.warning(this.$t("error.ItemNotExists"));
                return;
            }

            var selectedItem = selectedItems[0];

            var bill = {
                currencyGuid: this.currencyGuid,
                currencyValue: this.currencyValue
            };

            var mainCurrency = this.currencies.filter(function(obj) {
                return obj.isMain == true;
            })[0];

            row.barcode = selectedItem.barcode1;
            row.itemGuid = selectedItem.itemGuid;
            row.quantity = 1;
            row.discount = 0;
            row.extra = 0;

            //convert the selectedItem currency to bill currency
            selectedItem.price = selectedItem.costPrice;

            if (this.currencyGuid == mainCurrency.currencyGuid) {
                row.itemCostPrice = selectedItem.price;
            } else {
                row.itemCostPrice = selectedItem.costPrice = this.currencyConverter(
                    bill,
                    {
                        price: selectedItem.price,
                        currencyGuid: mainCurrency.currencyGuid,
                        currency: mainCurrency
                    }
                );
            }

            switch (this.priceType) {
                case 1:
                    selectedItem.price = selectedItem.price1;
                    row.price = this.currencyConverter(bill, selectedItem);
                    break;
                case 2:
                    selectedItem.price = selectedItem.price2;
                    row.price = this.currencyConverter(bill, selectedItem);
                    break;
                case 3:
                    selectedItem.price = selectedItem.price3;
                    row.price = this.currencyConverter(bill, selectedItem);
                    break;
                case 4:
                    selectedItem.price = selectedItem.lastBuyPrice;

                    if (this.currencyGuid == mainCurrency.currencyGuid) {
                        row.price = selectedItem.price;
                    } else {
                        row.price = this.currencyConverter(bill, {
                            price: selectedItem.price,
                            currencyGuid: mainCurrency.currencyGuid,
                            currency: mainCurrency
                        });
                    }
                    break;
                case 5:
                    selectedItem.price = selectedItem.costPrice;
                    if (this.currencyGuid == mainCurrency.currencyGuid) {
                        row.price = selectedItem.price;
                    } else {
                        row.price = this.currencyConverter(bill, {
                            price: selectedItem.price,
                            currencyGuid: mainCurrency.currencyGuid,
                            currency: mainCurrency
                        });
                    }
                    break;
                default:
                    selectedItem.price = 0;
                    row.price = 0;
                    break;
            }

            //save the "last buy price" for selectedItem in main currency
            bill = {
                currencyGuid: mainCurrency.currencyGuid,
                currencyValue: mainCurrency.currencyValue
            };

            if (this.currencyGuid == mainCurrency.currencyGuid) {
                row.lastBuyPrice = selectedItem.price;
            } else {
                row.lastBuyPrice = this.currencyConverter(bill, {
                    price: selectedItem.price,
                    currencyGuid: mainCurrency.currencyGuid,
                    currency: mainCurrency
                });
            }

            this.editedItem.billBodies.splice(index, 1);
            this.editedItem.billBodies.splice(index, 0, row);
        },
        deleteBillBody(item) {
            this.deletedBillBody = item;

            if (
                (this.deletedBillBody != undefined ||
                    this.deletedBillBody != null) &&
                (this.deletedBillBody.billBodyGuid != undefined ||
                    this.deletedBillBody.billBodyGuid != null)
            )
                this.deleteBillBodyConfirm = true;
            else this.deleteBodyConfirm();
        },
        deleteBodyConfirm() {
            var index = this.editedItem.billBodies.indexOf(
                this.deletedBillBody
            );
            this.editedItem.billBodies.splice(index, 1);
            this.closeDeleteBody();
        },
        closeDeleteBody() {
            this.deleteBillBodyConfirm = false;
        },
        currencyConverter(bill, item) {
            if (bill.currencyGuid == item.currencyGuid)
                return Number(item.price);
            else
                return (
                    Math.round(
                        ((Number(item.price) *
                            Number(item.currency.currencyValue)) /
                            Number(bill.currencyValue) +
                            Number.EPSILON) *
                            100
                    ) / 100
                );
        }
    }
};
</script>

<style>
#data-grid-demo {
    min-height: 700px;
}
.myTable {
    border: 1px solid #9e9e9e !important;
}
.myTable th,
.myTable td {
    border: 1px solid #9e9e9e !important;
    border-collapse: collapse;
    text-align: center;
}
.options {
    margin-top: 20px;
    padding: 20px;
    background: #f5f5f5;
}
.options .caption {
    font-size: 18px;
    font-weight: 500;
}
.option {
    margin-top: 10px;
}
.option > span {
    width: 120px;
    display: inline-block;
}
.option > .dx-widget {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    max-width: 350px;
}
#gridContainer {
    height: 440px;
}
</style>
